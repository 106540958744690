import React from 'react'
import Layout from '../components/common/Layout'
import { Card, Row } from 'antd'

const Oferta: React.FC = () => {
  return (
    <Layout>
      <Row align='middle' justify='center'>
        <Card title='Публічна оферта ' style={{ width: '90rem' }}>
          <div title='Page 1'>
            <div>
              <div>
                <div>
                  {/* <h2>
                    Публічна оферта
                    <br />
                    м. Запоріжжя&nbsp;Опубліковано:&nbsp;09 Січня 2024 р.
                  </h2>
                  <br /> */}
                  <p>
                    Цей документ (надалі &mdash;&nbsp;<strong>Оферта</strong>
                    &raquo;) є офіційною публічною пропозицією фізичної особи -
                    підприємця Степанян Софі Оганесівна&nbsp; укласти договір на
                    указаних нижче умовах (ч. 1 ст. 642 Цивільного кодексу
                    України).
                  </p>
                  <p>
                    Договір укладається між вами (користувачем сайту, тобто
                    замовником послуг, що пропонуються на Сайті, або ліцензіатом
                    контенту, що опублікований на
                    <strong> Сайті</strong>) (надалі &ndash; &laquo;
                    <strong>Споживач</strong>&raquo;) та мною -
                    <strong>
                      фізичною особою &ndash; підприємцем Степанян Софі
                      Оганесівна&nbsp;
                    </strong>
                    (код ЄДРПОУ 3675601889 , зареєстрований/а, номер запису про
                    включення відомостей до ЄДР No
                    <strong>2010350000000469124&nbsp;</strong>, платник єдиного
                    податку (надалі &ndash; &laquo;<strong>Виконавець</strong>
                    &raquo;).
                  </p>
                  <p>
                    Прийняти пропозицію до укладення цього Договору може
                    будь-яка дієздатна особа, зацікавлена в послугах та
                    ліцензіях&nbsp;
                    <strong>Сайту</strong>.
                  </p>
                  <p>
                    Приймаючи цю публічну оферту, Ви погоджуєтесь зі всіма
                    викладеними нижче умовами і підтверджуєте, що Вам зрозумілі
                    всі її положення та укладаєте на цих умовах договір про
                    надання послуг.
                  </p>
                  <p>
                    Приєднанням до цього Договору також вважатимуться
                    конклюдентні дії: проставлення галочки навпроти поля
                    &laquo;Ознайомлений із публічною офертою&raquo; та/або
                    оплата рахунку чи використання онлайн платіжного засобу для
                    оплати за онлайн курси та/або отримання доступу до онлайн
                    навчальних курсів. У разі незгоди з умовами цього Договору
                    укладення договору приєднання не є можливим.
                  </p>
                  <br></br>
                  <h3> 1.Терміни та визначення</h3>
                  <p>
                    <br />
                    1.1. Для цілей цього документа нижченаведені терміни
                    вживаються в такому значенні:
                    <br />
                    1.1.1.&nbsp;<strong>Споживач&nbsp;</strong>- дієздатна
                    особа, яка має законне право вступати в договірні відносини,
                    та погодилася з умовами цього Договору шляхом реєстрації
                    на&nbsp;
                    <strong>Сайті&nbsp;</strong>та користуванням&nbsp;
                    <strong>Сайтом</strong>.<br />
                    1.1.2.&nbsp;<strong>Вебінар&nbsp;</strong>- надання
                    інформаційних послуг, що здійснюється за допомогою
                    віддаленого доступу через мережу Інтернет, що проходить в
                    режимі реального часу. Вебінар може проводитися у формі
                    відео або аудіо трансляції, обміну повідомленнями, в інших
                    формах. Вебінар може передбачати можливість здійснення
                    зворотного зв'язку з особою, що надає інформаційні послуги.
                    <br />
                    1.1.3.&nbsp;<strong>Індивідуальне посилання&nbsp;</strong>-
                    унікальна гіпертекстове посилання, що дозволяє отримати
                    доступ до&nbsp;<strong>Продукту&nbsp;</strong>одноразово або
                    обмежена кількість разів.
                    <br />
                    1.1.4.&nbsp;<strong>Інформаційний курс&nbsp;</strong>-
                    сукупність інформаційних матеріалів (текстів, зображень,
                    аудіозаписів, аудіовізуальних документів) в електронній чи
                    паперовій формі та/чи інші матеріали та можливості,
                    передбачені&nbsp;<strong>Продуктом</strong>.<br />
                    1.1.5.&nbsp;<strong>Додаткові послуги&nbsp;</strong>-
                    послуги, не включені в конкретний&nbsp;
                    <strong>Інформаційний курс</strong>, або визначені&nbsp;
                    <strong>Виконавцем&nbsp;</strong>як додаткові: індивідуальне
                    (в тому числі дистанційне) консультування
                    <strong>Споживача&nbsp;</strong>і т.п.
                    <br />
                    1.1.6.&nbsp;<strong>Продукт&nbsp;</strong>&ndash;
                    об&rsquo;єкт реалізації, який складається із інформаційного
                    курсу та/чи певної кількості вебінарів та/чи особистих
                    зустрічей з представниками виконавця та/чи масових заходів,
                    обумовлених&nbsp;<strong>Виконавцем</strong>, навчальних і
                    пізнавальних матеріалів певної тематики в будь-якій формі
                    (аудіозаписи, відеозаписи, трансляції в режимі реального
                    часу, інтерактивні матеріали тощо), які поширюються
                    будь-якими каналами зв'язку. Вартість&nbsp;
                    <strong>Продукту&nbsp;</strong>
                    та конкретний перелік складових публікується на&nbsp;
                    <strong>Сайті</strong>.<br />
                    1.1.7.&nbsp;<strong>Сайт&nbsp;</strong>- сукупність
                    програмного та апаратного забезпечення, що забезпечує
                    публікацію для загального огляду інформації в мережі
                    Інтернет і доступного за унікальною інтернет адресою
                    <strong>http://strongmind.academy&nbsp;</strong>. Може
                    містити графічну, текстову, аудіо-, відео-, а також іншу
                    інформацію, записану і читану за допомогою ЕОМ, доступ до
                    якої забезпечується через мережу Інтернет.
                    <br />
                    1.1.8.&nbsp;<strong>Особистий кабінет&nbsp;</strong>-
                    спеціалізований розділ&nbsp;<strong>Сайту&nbsp;</strong>або
                    ресурс, доступний після авторизації з використанням логіна і
                    пароля&nbsp;<strong>Споживача</strong>, в якому міститься
                    інформація&nbsp;<strong>Споживача&nbsp;</strong>(дані
                    <strong>Споживача</strong>, статистика і т.п.), матеріали
                    вебінарів і&nbsp;
                    <strong>Інформаційних курсів Споживача</strong>, механізми
                    віддаленого взаємодії&nbsp;
                    <strong>Сторін&nbsp;</strong>в рамках договору та керування
                    вмістимим.
                    <br />
                    1.2. У Договорі можуть бути використані терміни, не
                    визначені в п. 1.1. В цьому випадку тлумачення такого
                    терміну проводиться відповідно до тексту Договору. У разі
                    відсутності однозначного тлумачення терміну в тексті
                    Договору слід керуватися тлумаченням терміну, визначеним: в
                    першу чергу - законодавством України, в другу чергу - на
                    сайті Сервісу, потім -
                  </p>
                  <p>
                    сформованим (загальновживаним значенням) в мережі Інтернет.
                  </p>
                  <p>
                    <br />
                    <h3>2. Предмет договору</h3>
                    <br />
                    2.1.&nbsp;<strong>Виконавець&nbsp;</strong>надає платні
                    інформаційно-консультаційні послуги у вигляді організації та
                    надання можливості особистої участі в онлайн та оффлайн
                    заходах (вебінарах в режимі реального часу, в
                    майстер-класах, тренінгах, курсах, інтенсивах, шоу, інших
                    заходів подібного характеру, надалі &laquo;
                    <strong>Заходи</strong>&raquo;), а також надає платні
                    невиключні ліцензії на певний строк, які надають право
                    доступу і ознайомлення з платним контентом у вигляді
                    навчальних і пізнавальних матеріалів певної тематики в
                    будь-якій формі (аудіозаписи, відеозаписи, трансляції в
                    режимі реального часу, інтерактивні матеріали тощо), які
                    поширюються будь-якими каналами зв'язку (надалі &ndash;
                    &laquo;<strong>Контент</strong>
                    &raquo;). 2.2 Перелік&nbsp;<strong>Послуг</strong>,&nbsp;
                    <strong>Контенту</strong>, які повинні бути надані&nbsp;
                    <strong>Виконавцем</strong>, способи їх надання визначаються
                    в описі відповідного&nbsp;<strong>Заходу&nbsp;</strong>
                    або&nbsp;
                    <strong>Інформаційного курсу&nbsp;</strong>на&nbsp;
                    <strong>Сайті</strong>. Вартість конкретного&nbsp;
                    <strong>Інформаційного курсу</strong>,&nbsp;
                    <strong>Заходу&nbsp;</strong>або&nbsp;
                    <strong>Контенту&nbsp;</strong>визначена на&nbsp;
                    <strong>Сайті&nbsp;</strong>або рекламних матеріалах.
                    <br />
                    2.3.&nbsp;<strong>Споживач</strong>, відповідно до умов
                    даного договору здійснює оплату послуг, ліцензії&nbsp;
                    <strong>Виконавця</strong>. Цей Договір укладається на
                    умовах договору приєднання (ст. 634 Цивільного кодексу
                    України) та вважається укладеним з моменту прийняття
                    публічної оферти.
                    <br />
                    2.4. Приймаючи публічну оферту на укладення такого
                    Договору,&nbsp;<strong>Споживач&nbsp;</strong>підтверджує,
                    що він повністю приймає його умови.
                    <br />
                    2.5.&nbsp;<strong>Споживач&nbsp;</strong>приймає (акцептує)
                    оферту шляхом: здійснення конклюдентних дій &ndash; оплати
                    вибраного&nbsp;<strong>Курсу&nbsp;</strong>на умовах,
                    зазначених у розділі 4 цього Договору. Письмова форма цього
                    Договору згідно ЗУ &laquo;Про електронну комерцію&raquo;
                    додатково може підтверджуватися направленням електронного
                    листа.
                  </p>
                  <p>
                    <br />
                    <h3>3. Вартість і опис послуг</h3>
                    <br />
                    3.1. Обсяг доступних&nbsp;<strong>Споживачу&nbsp;</strong>
                    послуг залежить від придбаного&nbsp;
                    <strong>Продукту&nbsp;</strong>
                    (пакета послуг).
                    <br />
                    Якщо&nbsp;<strong>Споживач&nbsp;</strong>користується
                    послугами або матеріалами, доступними в відкритих розділах
                    сайту, він отримує тільки базову інформацію щодо необхідної
                    тематики. У разі придбання платних послуг,
                    <strong>Контенту</strong>,&nbsp;
                    <strong>Споживач&nbsp;</strong>отримує розширену інформацію,
                    а також доступ до платного контенту і платних сервісів сайту
                    і&nbsp;<strong>Заходів</strong>, передбачених умовами
                    відповідного&nbsp;<strong>Продукту</strong>.&nbsp;
                    <strong>Споживач&nbsp;</strong>може придбати окремий&nbsp;
                    <strong>Продукт&nbsp;</strong>(пакет послуг) за одноразову
                    плату.
                    <br />
                    Деякі передплачені пакети послуг передбачають отримання
                    доступу відразу до декількох сервісів і програм (курсів)
                    навчання. Однак, якщо це не так, для отримання інформаційних
                    матеріалів з інших курсів навчання,&nbsp;
                    <strong>Споживачу&nbsp;</strong>
                    необхідно придбати такі послуги окремо.
                    <br />
                    Деякі курси навчання мають на увазі самостійну роботу і
                    виконання певних завдань (домашніх завдань), які є частиною
                    процесу надання послуг.
                    <br />
                    Якщо&nbsp;<strong>Споживач&nbsp;</strong>не виконує домашні
                    завдання, передбачені умовами відповідного&nbsp;
                    <strong>Продукту</strong>, або виконує після строку,
                    відведеного на їх виконання, то такий&nbsp;
                    <strong>Споживач&nbsp;</strong>не отримує персонального
                    зворотного зв&rsquo;язку щодо такого домашнього завдання. В
                    цьому випадку&nbsp;Послуги Виконавцявважаються наданими
                    повністю і належним чином, та прийнятими&nbsp;
                    <strong>Споживачем</strong>.<br />
                    3.2. Вартість послуг або ліцензії публікується на сторінці
                    замовлення послуг/ліцензії на Сайті або в електронних
                    повідомленнях, які направляються&nbsp;
                    <strong>Споживачу</strong>
                    .<br />
                    Остаточна вартість послуг або ліцензії у валюті платежу
                    (грн) фіксується в виставленому
                    <strong>Споживачеві&nbsp;</strong>
                    рахунку, відображається на сторінці оплати послуг, а також в
                    банківській виписці по картці.
                    <br />
                    3.3. Якщо&nbsp;<strong>Споживач&nbsp;</strong>зареєструвався
                    на&nbsp;<strong>Сайті&nbsp;</strong>або оформив замовлення
                    на пропоновані на&nbsp;Сайтіпослуги, або ж фактично
                    користується послугами, контентом&nbsp;
                    <strong>Виконавця</strong>, то&nbsp;Споживачпогоджується з
                    тим, що&nbsp;
                    <strong>Виконавець&nbsp;</strong>може відправляти йому
                    електронні листи, повідомлення та повідомлення, які містять
                    рекламну інформацію: рекламні розсилки, інформацію про
                    послуги і акції (або акції партнерів&nbsp;
                    <strong>Виконавця</strong>) за номером телефону і
                    електронній пошті, зазначеним
                    <strong>Споживачем&nbsp;</strong>при реєстрації або
                    оформленні замовлення. При необхідності,&nbsp;
                    <strong>Споживач&nbsp;</strong>завжди може відмовитися від
                    їх отримання.
                    <br />
                    <strong>Споживач&nbsp;</strong>повинен дотримуватися всіх
                    умов Договору і правил надання послуг, з якими
                    <strong>Споживачеві&nbsp;</strong>буде запропоновано
                    ознайомитися при оформленні замовлення або в процесі
                    користування послугами.
                    <br />
                    <strong>Споживач&nbsp;</strong>погоджується з тим, що він не
                    буде брати участь в діяльності, яка порушує функціонування
                    сайту, послуг або пов'язаних з ними серверів і мереж, а
                    також діяльності, яка може паплюжити ділову репутацію&nbsp;
                    <strong>Виконавця</strong>.<br />
                    <strong>Споживач&nbsp;</strong>приймає на себе повну
                    відповідальність за порушення своїх зобов'язань, обумовлених
                    в цьому договорі, а також за всі наслідки цих порушень.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div title='Page 3'>
            <div>
              <div>
                <div>
                  <br />
                  <h3>4. Умови надання Послуг</h3>
                  <br />
                  <p>
                    4.1. Під&nbsp;<strong>Послугами</strong>, що надаються&nbsp;
                    <strong>Виконавцем</strong>, в залежності від вибору&nbsp;
                    <strong>Споживачем Продукту</strong>, мається на увазі:
                    <br />
                    4.1.1. надання інформаційних послуг шляхом надання доступу
                    до&nbsp;<strong>Заходів</strong>, зокрема, але не виключно
                    вебінарів, курсів, майстер-класів, лекцій, консультацій
                    тощо;
                  </p>
                  <p>
                    4.1.2. надання інформаційних послуг шляхом передачі&nbsp;
                    <strong>Споживачев</strong>і&nbsp;
                    <strong>Інформаційного курсу&nbsp;</strong>в електронному
                    вигляді
                    <br />
                    4.1.3. індивідуальні онлайн чи оффлайн зустрічі
                    представників&nbsp;<strong>Виконавця&nbsp;</strong>із&nbsp;
                    <strong>Споживачем</strong>, участь у масових заходах,
                    організованих&nbsp;<strong>Виконавцем</strong>;
                  </p>
                  <p>
                    4.1.4. консультації (відповіді на повідомлення електронною
                    поштою, у особистому кабінеті, через skype, zoom, getcourse)
                    особистого куратора чи куратора групи, закріпленого
                    менеджера.
                    <br />
                    4.2. В рамках цього договору надання доступу до&nbsp;
                    <strong>Заходів&nbsp;</strong>здійснюється на наступних
                    умовах.
                    <br />
                    4.2.1.&nbsp;<strong>Захід&nbsp;</strong>проводиться&nbsp;
                    <strong>Виконавцем&nbsp;</strong>в дату і час, зазначені
                    на&nbsp;<strong>Сайті</strong>, а також в Повідомленні в
                    особистому кабінеті та/чи електронною поштою.
                    Тривалість&nbsp;
                    <strong>Заходу&nbsp;</strong>визначається&nbsp;
                    <strong>Виконавцем</strong>. До проведення&nbsp;
                    <strong>Заходу Виконавець&nbsp;</strong>має право залучати
                    будь-яких осіб на власний розсуд.&nbsp;Споживачсамостійно
                    забезпечує себе обладнанням, необхідним для участі у
                    вебінарі. Технологічний майданчик для проведення&nbsp;
                    <strong>Заходів&nbsp;</strong>визначається&nbsp;
                    <strong>Виконавцем&nbsp;</strong>самостійно.
                  </p>
                  <p>
                    4.2.2. Зміст&nbsp;<strong>Заходу&nbsp;</strong>
                    визначається&nbsp;<strong>Виконавцем&nbsp;</strong>і має
                    відповідати опису відповідного&nbsp;<strong>Заходу</strong>.
                    <br />
                    4.2.3. У разі пропуску&nbsp;<strong>Заходу</strong>, в тому
                    числі частини&nbsp;<strong>Заходу&nbsp;</strong>з причин,
                    незалежних від&nbsp;<strong>Виконавця</strong>,
                    <strong>Споживач&nbsp;</strong>не має права вимагати
                    повторного проведення&nbsp;<strong>Заходів</strong>.<br />
                    Запис онлайн-<strong>Заходу&nbsp;</strong>може бути
                    наданий&nbsp;<strong>Споживачеві&nbsp;</strong>на його
                    вимогу, якщо це передбачено в описі умов відповідного&nbsp;
                    <strong>Продукту</strong>.<br />
                    При цьому інформаційні послуги вважаються наданими належним
                    чином.
                    <br />
                    4.2.4.&nbsp;<strong>Виконавець&nbsp;</strong>має право
                    припинити надання послуг у разі некоректної поведінки
                    споживача, що заважає проведенню вебінару. Вартість
                    вебінару, в ході якого надання послуг було призупинено з
                    причин, вказаних у даному пункті, не відшкодовується.
                    <br />
                    4.2.5. У разі якщо це передбачено&nbsp;
                    <strong>Продуктом</strong>,&nbsp;
                    <strong>Виконавець&nbsp;</strong>може надавати&nbsp;
                    <strong>Споживачеві&nbsp;</strong>доступ до запису вебінару.
                    <br />
                    4.2.6. Для отримання&nbsp;
                    <strong>Інформаційного курсу Споживачу&nbsp;</strong>
                    направляється електронний лист з посиланням на електронну
                    пошту, що вказана&nbsp;<strong>Споживачем</strong>, або смс
                    на номер телефону, який вказаний&nbsp;
                    <strong>Споживачем</strong>.&nbsp;
                    <strong>Споживач&nbsp;</strong>переходить за посиланням,
                    після чого може здійснити доступ до Інформаційного
                    курсу.&nbsp;
                    <strong>Інформаційний курс&nbsp;</strong>вважається
                    переданим, а зобов'язання&nbsp;Виконавцявиконаними з моменту
                    відправки&nbsp;
                    <strong>Виконавцем&nbsp;</strong>електронного листа, смс, що
                    містить Інформаційний курс, за адресою, вказаною&nbsp;
                    <strong>Споживачем</strong>.<br />
                    4.2.7. Якщо доступ до&nbsp;
                    <strong>Інформаційного курсу&nbsp;</strong>здійснюється в
                    Особистому кабінеті&nbsp;<strong>Споживача</strong>, то з
                    моменту розміщення в Особистому кабінеті доступу до&nbsp;
                    <strong>Інформаційного курсу&nbsp;</strong>
                    зобов&rsquo;язання
                    <strong>Виконавця&nbsp;</strong>вважаються виконаними, а
                    доступ до&nbsp;
                    <strong>Інформаційних матеріалів&nbsp;</strong>наданим у
                    повному обсязі. Строк надання доступу до&nbsp;
                    <strong>Інформаційного курсу&nbsp;</strong>обмежується
                    терміном, який зазначений в описі відповідного&nbsp;
                    <strong>Продукту</strong>.<br />
                    4.2.8.&nbsp;<strong>Споживач&nbsp;</strong>самостійно
                    забезпечує себе обладнанням та технічними можливостями,
                    необхідним для перегляду, скачування і використання
                    Інформаційного курсу.
                    <br />
                    4.2.9. Зміст&nbsp;
                    <strong>Інформаційного курсу&nbsp;</strong>
                    визначається&nbsp;<strong>Виконавцем&nbsp;</strong>і має
                    відповідати переліку, представленому в описі
                    відповідного&nbsp;
                    <strong>Заходу&nbsp;</strong>або&nbsp;
                    <strong>Контенту&nbsp;</strong>на&nbsp;
                    <strong>Сайті</strong>.
                    <br />
                    4.3. В рамках цього Договору індивідуальні онлайн чи оффлайн
                    зустрічі представників&nbsp;Виконавцяіз&nbsp;
                    <strong>Споживачем</strong>, участь у масових заходах,
                    організованих&nbsp;<strong>Виконавцем&nbsp;</strong>
                    здійснюється на наступних умовах.
                    <br />
                    4.3.1. Усі зустрічі та масові заходи проводяться&nbsp;
                    <strong>Виконавцем&nbsp;</strong>в дату і час, зазначені
                    на&nbsp;<strong>Сайті</strong>, а також в Повідомленні,
                    надісланому електронною поштою. При цьому, якщо інформація,
                    зазначена на&nbsp;<strong>Сайті</strong>, відрізняється від
                    інформації зазначеної в Повідомленні,&nbsp;
                    <strong>Споживач&nbsp;</strong>зобов'язаний керуватися
                    інформацією, вказаною в Повідомленні. Тривалість зустрічей
                    та заходів визначається<strong>Виконавцем</strong>. До
                    проведення зустрічей та/чи заходів&nbsp;
                    <strong>Виконавець&nbsp;</strong>
                    має право залучати будь-яких осіб на власний розсуд.
                    <br />
                    4.3.2. Зміст зустрічей та заходів визначається&nbsp;
                    <strong>Виконавцем&nbsp;</strong>і має відповідати опису,
                    представленому на&nbsp;<strong>Сайті</strong>.&nbsp;
                    <strong>Споживач&nbsp;</strong>не має права давати будь-яких
                    вказівок щодо змісту масового заходу.&nbsp;
                    <strong>Споживач&nbsp;</strong>має право висловлювати власні
                    побажання щодо змісту індивідуальних онлайн та оффлайн
                    зустрічей.&nbsp;<strong>Виконавець&nbsp;</strong>має право
                    змінити зміст індивідуальної зустрічі з урахуванням
                    побажань&nbsp;<strong>Споживача</strong>, якщо таке
                    передбачено&nbsp;<strong>Продуктом</strong>.<br />
                    4.3.3. У разі пропуску&nbsp;
                    <strong>Споживачем&nbsp;</strong>
                    зустрічі та/чи заходу, в тому числі їх частини з причин,
                    незалежних від&nbsp;<strong>Виконавця</strong>,&nbsp;
                    <strong>Споживач&nbsp;</strong>не має права вимагати
                    повторного проведення або доведення до його відома
                    інформації, що була подана на заході, в іншій формі. При
                    цьому інформаційні послуги вважаються наданими належним
                    чином.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div title='Page 4'>
            <div>
              <div>
                <div>
                  <br />
                  <h3>5. Конфіденційність</h3>
                  <br />
                  <p>
                    5.1. Метою цього розділу Договору є захист інформації,
                    яку&nbsp;
                    <strong>Сторони&nbsp;</strong>надають (розкривають) один
                    одному в ході співпраці по Договору.
                    <br />
                    5.2.&nbsp;<strong>Сторони&nbsp;</strong>погоджуються вважати
                    весь обсяг інформації наданої один одному в рамках цього
                    Договору або, в зв'язку з позначеної в ньому метою,
                    Конфіденційною інформацією (а в межах, що допускаються
                    чинним Українським законодавством - комерційною таємницею),
                    якщо виходячи з Договору, прямо не визначає інше.
                  </p>
                  <p>
                    5.3. Кожна&nbsp;<strong>Сторона</strong>, яка отримує
                    конфіденційну інформацію (надалі -&nbsp;
                    <strong>Отримуюча Сторона</strong>) від іншої&nbsp;
                    <strong>Сторони</strong>, не має права повідомляти
                    конфіденційну інформацію будь-кому без прямого на те дозволу
                    іншої&nbsp;
                    <strong>Сторони&nbsp;</strong>і повинна вживати всіх
                    можливих заходів для захисту Конфіденційної Інформації,
                    включаючи, зокрема, всі заходи, які вона приймає для захисту
                    власної конфіденційної інформації / комерційної таємниці.
                  </p>
                  <p>
                    5.4.&nbsp;<strong>Сторона</strong>, зобов'язується
                    використовувати розкриту конфіденційну інформацію виключно з
                    метою реалізації цього Договору.
                    <br />
                    5.5.&nbsp;<strong>Сторона</strong>, зобов'язується обмежити
                    перелік осіб, які мають доступ до Конфіденційної інформації,
                    виключно своїми співробітниками, або співробітниками своїх
                    контрагентів, які безпосередньо беруть участь у виконанні
                    Договору, з якими є аналогічна угода про конфіденційність.
                  </p>
                  <p>
                    5.6. Зобов'язання, викладені у даному розділі Договору, не
                    поширюються на такі відомості:
                    <br />
                    5.6.1. відомості, які є або стають загальновідомими не з
                    вини&nbsp;<strong>Сторони</strong>, що отримала дані
                    відомості від іншої сторони Договору, що повинно бути
                    підтверджено відповідними доказами;
                    <br />
                    5.6.2. відомості, які були отримані&nbsp;
                    <strong>Сторонами&nbsp;</strong>від третіх осіб без
                    зобов'язання про збереження конфіденційності;
                    <br />
                    5.6.3. відомості, самостійно розроблені&nbsp;
                    <strong>Стороною</strong>;<br />
                    5.6.4. відомості, розкриття яких необхідно відповідно до
                    вимог законодавства або органів, які мають відповідні
                    повноваження. Дані відомості можуть бути надані тільки на
                    адресу органів, що мають відповідні повноваження, в порядку,
                    передбаченому застосовуваним законодавством.
                    <br />
                    5.7. Не є розголошенням надання конфіденційної інформації
                    третім особам при наявності письмової згоди&nbsp;
                    <strong>Сторони</strong>, що надала конфіденційну інформацію
                    на її розкриття.
                    <br />
                    5.8. За кожне порушення зобов'язань про нерозголошення
                    Конфіденційної інформації, передбачених цим Договором,&nbsp;
                    <strong>Сторона</strong>, яка здійснила подібне порушення,
                    зобов'язана відшкодувати іншій&nbsp;
                    <strong>Стороні&nbsp;</strong>прямі збитки, понесені у
                    зв'язку з цим порушенням .<br />
                    5.9. Зобов'язання, встановлені цим Договором щодо охорони
                    Конфіденційної інформації, дійсні протягом усього терміну
                    дії договору.
                    <br />
                    5.10.&nbsp;<strong>Виконавець&nbsp;</strong>має повний
                    доступ до контенту&nbsp;<strong>Споживача&nbsp;</strong>
                    (файлів, текстів і т.п.), баз даних і будь-який інший
                    інформації&nbsp;
                    <strong>Споживача&nbsp;</strong>розміщеної в особистому
                    кабінеті. Вся ця інформація прирівнюється до Конфіденційної
                    інформації, переданою від&nbsp;
                    <strong>Споживача Виконавцю</strong>, і підлягає захисту
                    згідно з умовами цього договору.
                    <br />
                    5.11. Створення і зберігання резервних копій даних&nbsp;
                    <strong>Споживача&nbsp;</strong>не є порушенням
                    конфіденційності інформації&nbsp;<strong>Споживача</strong>.
                  </p>
                  <p>
                    <br />
                    <h3>6. Права і обов'язки Виконавця</h3>
                    <br />
                    6.1.&nbsp;<strong>Виконавець&nbsp;</strong>зобов'язується:
                    <br />
                    6.1.1. Надавати&nbsp;<strong>Послуги&nbsp;</strong>
                    відповідно до умов Договору та обраного&nbsp;Споживачем
                    Продукту.
                    <br />
                    6.1.2. Повідомляти про всі технічні та профілактичні роботи,
                    про зміни способів доступу до ресурсів, їх адресу і т. д.
                    на&nbsp;
                    <strong>Сайті&nbsp;</strong>або шляхом надсилання листа на
                    адресу електронної пошти, яка вказана
                    <strong>Споживачем&nbsp;</strong>для зв'язку.
                    <br />
                    6.2.&nbsp;<strong>Виконавець&nbsp;</strong>має право:
                    <br />
                    6.2.1. В односторонньому порядку змінити терміни виконання
                    зобов'язань за цим Договором або повністю відмовитись від
                    надання послуг у разі порушення&nbsp;
                    <strong>Споживачем&nbsp;</strong>умов цього Договору щодо
                    строків оплати послуг чи інших зобов&rsquo;язань за цим
                    Договором.
                    <br />
                    6.2.2. Організовувати контроль за строками і якістю
                    виконання домашніх завдань&nbsp;<strong>Споживача</strong>.
                    6.2.3. Для виконання зобов'язань за цим Договором,
                    самостійно та на власний розсуд залучати третіх осіб і
                    вступати з ними в договірні відносини, без погодження
                    з&nbsp;<strong>Споживачем</strong>. При цьому
                    <strong>Виконавець&nbsp;</strong>залишається відповідальним
                    перед&nbsp;<strong>Споживачем&nbsp;</strong>за дії чи
                    бездіяльність третіх осіб, залучених&nbsp;
                    <strong>Виконавцем&nbsp;</strong>до виконання умов цього
                    Договору.
                    <br />
                    6.2.4. Здійснювати контроль за змістом та належністю
                    повідомлень&nbsp;<strong>Споживача&nbsp;</strong>у
                    спільнотах в мережі Інтернет та форумах&nbsp;
                    <strong>Виконавця</strong>, застосовувати санкції та інші
                    заходи з контролю над повідомленнями та темами.
                    <br />
                    6.2.5. Позбавляти права писати повідомлення, позбавляти
                    учасника права на публікацію повідомлень чи заборона на
                    участь у спільнотах/форумі (бан).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div title='Page 5'>
            <div>
              <div>
                <div>
                  <p>
                    6.2.6. Змінювати на власний розсуд дату та час проведення
                    масових заходів та зустрічей за умови
                  </p>
                  <p>
                    попередження про це&nbsp;<strong>Споживача&nbsp;</strong>не
                    пізніше ніж за 7 календарних дні до дати проведення
                    заходу/зустрічі.
                    <br />
                    6.3.&nbsp;<strong>Виконавець&nbsp;</strong>залишає за собою
                    право негайно припинити надання&nbsp;
                    <strong>Послуг&nbsp;</strong>та/або розірвати Договір в
                    безумовному порядку (без повернення сплачених коштів) в
                    наступних випадках:
                    <br />
                    6.3.1. Неотримання від&nbsp;<strong>Споживача&nbsp;</strong>
                    оплати в повному обсязі за вартість відповідних&nbsp;
                    <strong>Послуг&nbsp;</strong>в належні терміни.
                    <br />
                    6.3.2. Якщо&nbsp;<strong>Виконавець&nbsp;</strong>вважає, що
                    будь-які дії та / або бездіяльність&nbsp;
                    <strong>Споживача</strong>, завдають чи можуть завдати
                    шкоди&nbsp;<strong>Виконавцю&nbsp;</strong>(в тому числі
                    принижують його честь, гідність і ділову репутацію, та / або
                    перешкоджають нормальній роботі інших&nbsp;
                    <strong>Споживачів</strong>), або нормальному
                    функціонуванню&nbsp;<strong>Сайту</strong>.<br />
                    6.3.4. При розміщенні&nbsp;<strong>Споживачем&nbsp;</strong>
                    інформації, яка принижує честь і гідність інших людей, яка
                    суперечить чинному законодавству України або міжнародним
                    правом, еротичного та порнографічного характеру, а так само
                    інформації, програмного забезпечення або інших матеріалів,
                    які повністю або частково захищені авторським та / або
                    суміжним правом (без згоди власника авторських прав),
                    містять в собі комп'ютерні віруси або інші подібні
                    компоненти.&nbsp;Споживачусвідомлює, що в зв'язку з
                    відсутністю встановлених законодавством методик визначення
                    того, чи подається конкретне зображення таким, що суперечить
                    нормам чинного законодавства України чи міжнародного
                    права,&nbsp;
                    <strong>Виконавець&nbsp;</strong>залишає за собою право
                    такого визначення.
                    <br />
                    6.3.5. За рішенням уповноваженого державного органу
                    відповідно до чинного законодавства України.
                    <br />
                    6.3.6. При встановленні фактів (включаючи, але не
                    обмежуючись) масових розсилок&nbsp;Споживачемрекламних
                    листів, спаму через будь-які засоби мережі Інтернет,
                    поширення інформації, яка дискредитує&nbsp;
                    <strong>Виконавця</strong>.&nbsp;
                    <strong>Споживач&nbsp;</strong>погоджується, що у
                    зв&rsquo;язку з відсутністю методики оцінки інформації як
                    дискредитуючої, чи такої, що принижує честь, гідність та
                    шкодить діловій репутації, оцінка змісту інформації
                    здійснюватиметься на розсуд&nbsp;
                    <strong>Виконавця</strong>.<br />
                    6.3.7. У разі якщо&nbsp;<strong>Споживачем&nbsp;</strong>з
                    моменту направлення&nbsp;<strong>Виконавцем&nbsp;</strong>
                    повідомлення по електронній пошті про призупинення надання
                    Послуг протягом 7 днів, не зв'яжеться з&nbsp;
                    <strong>Виконавцем&nbsp;</strong>і не усуне порушення, які
                    призвели до припинення надання&nbsp;<strong>Послуг</strong>.
                  </p>
                  <p>
                    <br />
                    <h3>7. Права та обов'язки Споживача</h3>
                    <br />
                    <br />
                    7.1.&nbsp;<strong>Споживач&nbsp;</strong>зобов'язується:
                    <br />
                    7.1.1. Строго виконувати умови Договору.
                    <br />
                    7.1.2. Надати&nbsp;<strong>Виконавцю&nbsp;</strong>
                    інформацію про бажаний рівень отримання знань.
                    <br />
                    7.1.3. При отриманні&nbsp;<strong>Послуг&nbsp;</strong>
                    дотримуватись правил встановлених&nbsp;
                    <strong>Виконавцем</strong>.<br />
                    7.1.4. Приймати від&nbsp;<strong>Виконавця&nbsp;</strong>
                    послуги, що надаються відповідно до цього Договору.
                    <br />
                    7.1.5. Оплачувати послуги, на умовах і в порядку зазначеному
                    в розділі 7 цього Договору.
                    <br />
                    7.1.6. Виконувати рекомендації і завдання, які дає&nbsp;
                    <strong>Виконавець&nbsp;</strong>та/чи Особистий куратор.
                    <br />
                    7.1.7. Виконувати всі домашні завдання, які надає&nbsp;
                    <strong>Виконавець&nbsp;</strong>самостійно, у встановлений
                    строк і в повному обсязі.
                  </p>
                  <p>
                    7.1.8. Не розголошувати інформацію щодо вартості
                    послуг&nbsp;
                    <strong>Виконавця&nbsp;</strong>та іншу інформацію, що
                    відповідно до умов цього Договору визначена&nbsp;
                    <strong>Сторонами&nbsp;</strong>конфіденційною.
                    <br />
                    7.1.9. У 7 -денний строк письмово повідомляти&nbsp;
                    <strong>Виконавця&nbsp;</strong>про зміну особистої
                    інформації (номеру телефону, електронної адреси, тощо). В
                    іншому випадку&nbsp;<strong>Виконавець&nbsp;</strong>не несе
                    відповідальності за неотримання&nbsp;
                    <strong>Споживачем&nbsp;</strong>інформації щодо будь-яких
                    змін в процесі надання&nbsp;<strong>Послуг</strong>. Обмін
                    інформацією здійснюється за допомогою засобів зв&rsquo;язку,
                    визначених у Договорі.
                  </p>
                  <p>
                    7.1.10. Не завдавати шкоди програмній оболонці, технічним і
                    програмним засобам&nbsp;<strong>Виконавця&nbsp;</strong>і
                    третіх осіб.
                    <br />
                    7.1.11. Періодично відвідувати&nbsp;<strong>Сайт</strong>,
                    для ознайомлення з офіційною інформацією&nbsp;
                    <strong>Виконавця</strong>, яка стосується надання&nbsp;
                    <strong>Послуг,&nbsp;</strong>а також, регулює
                    відносини&nbsp;
                    <strong>Виконавця&nbsp;</strong>і&nbsp;
                    <strong>Споживача</strong>.
                  </p>
                  <p>
                    7.1.12. Зберігати конфіденційність облікових даних
                    наданих&nbsp;
                    <strong>Виконавцем</strong>.<br />
                    7.1.13. Не здійснювати інші дії, не передбачені Договором,
                    що містять склад кримінального або адміністративного
                    правопорушення, або порушують права і законні інтереси&nbsp;
                    <strong>Виконавця&nbsp;</strong>або третіх осіб.
                    <br />
                    7.2.&nbsp;<strong>Споживач&nbsp;</strong>має право:
                    <br />
                    7.2.1. Вимагати від&nbsp;<strong>Виконавця&nbsp;</strong>
                    надання&nbsp;<strong>Послуг&nbsp;</strong>відповідно до
                    цього Договору.
                    <br />
                    7.2.2. Змінювати обраний&nbsp;<strong>Продукт&nbsp;</strong>
                    на будь-який інший більший за обсягом та вартістю, доступний
                    у списку Продуктів, шляхом надання відповідного
                    повідомлення&nbsp;
                    <strong>Виконавцю</strong>.&nbsp;
                    <strong>Виконавець&nbsp;</strong>може надати окреме
                    посилання на доплату за відповідний&nbsp;
                    <strong>Продукт</strong>.&nbsp;
                    <strong>Виконавець&nbsp;</strong>вносить зміни після
                    отримання оплати від&nbsp;<strong>Споживача</strong>.
                    Зміна&nbsp;
                    <strong>Продукту&nbsp;</strong>на менший по обсягу чи
                    вартості не допускається.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div title='Page 6'>
            <div>
              <div>
                <div>
                  <p>
                    7.2.3. Призупинити або припинити отримання&nbsp;
                    <strong>Послуги&nbsp;</strong>в будь-який час з дотриманням
                    всіх встановлених Договором вимог.
                    <br />
                    7.2.4. Брати участь у дискусіях, обговореннях, висловлювати
                    свою думку з питань, піднятих в суспільстві/форумі.
                  </p>
                  <p>
                    7.2.5. Використовувати усі функції особистого кабінету для
                    отримання необхідної інформації, створення повідомлень тощо.
                    <br />
                    7.2.6. Звертатися до Особистого куратора та/чи інших
                    представників&nbsp;<strong>Виконавця&nbsp;</strong>з питань,
                    що виникають в ході надання послуг, отримання
                    роз&rsquo;яснень раніше наданих консультацій та/чи
                    відповідей.
                  </p>
                  <p>
                    7.2.7. Звертатися до представника&nbsp;
                    <strong>Виконавця&nbsp;</strong>з приводу питань, що не
                    стосуються надання<strong>Послуг&nbsp;</strong>за цим
                    Договором, а саме технічних, організаційних питань тощо.
                    <br />
                    7.2.8. Відмовитися від замовлених послуг, у строк та у
                    випадку, якщо таке передбачено умовами
                    <strong>Продукт</strong>у письмово повідомивши&nbsp;
                    <strong>Виконавця</strong>.
                  </p>
                  <p>
                    7.2.9. Знати по якій причині застосовано санкції в порядку
                    контролю над змістом повідомлень та темами.
                    <br />
                    7.2.10. Надіслати&nbsp;<strong>Виконавцю&nbsp;</strong>
                    обґрунтовану скаргу (апеляцію) у випадку незгоди із
                    застосованою санкцією в порядку контролю над змістом
                    повідомлень та темами приватним повідомленням.
                  </p>
                  <br />
                  <h3>8. Вартість Продукту та порядок розрахунків</h3>
                  <br />

                  <p>
                    8.1. Вартість, обсяг, і порядок надання послуг, ліцензії
                    визначається в залежності від обраного
                    <strong>Споживачем Продукту</strong>.<br />
                    8.2. За надання&nbsp;
                    <strong>Інформаційно-консультаційної послуги</strong>, або
                    Ліцензії&nbsp;<strong>Споживач&nbsp;</strong>сплачує
                    вартість, визначені в описі відповідного&nbsp;
                    <strong>Продукту.</strong>.
                  </p>
                  <p>
                    8.3. Вартість&nbsp;<strong>Послуг</strong>, ліцензії та їх
                    оплата здійснюється&nbsp;<strong>Споживачем&nbsp;</strong>в
                    національній валюті України (гривня).
                    <br />
                    8.4. У вартість&nbsp;<strong>Послуг&nbsp;</strong>не
                    включається ПДВ.
                    <br />
                    8.5. В рамках Договору приймається оплата&nbsp;
                    <strong>Продукту&nbsp;</strong>будь-яким зручним
                    для&nbsp;Споживачаспособом, що не заборонені чинним
                    законодавством України в строк 7 денного терміну з дати
                    отримання&nbsp;<strong>Рахунку-фактури Виконавця</strong>/
                    платіжною карткою на&nbsp;<strong>Сайті&nbsp;</strong>
                    виконавця після обрання відповідного&nbsp;
                    <strong>Продукту</strong>.
                  </p>
                  <p>
                    8.6. Приймання-передача наданих&nbsp;
                    <strong>Послуг&nbsp;</strong>здійснюється після закінчення
                    надання послуг за цим Договором без обов&rsquo;язкового
                    підписання&nbsp;
                    <strong>
                      Сторонами Акта приймання-передачі наданих послуг
                    </strong>
                    . У такому випадку про прийняття&nbsp;
                    <strong>Споживачем&nbsp;</strong>у повному обсязі за якістю
                    та кількістю наданих&nbsp;<strong>Виконавцем&nbsp;</strong>
                    послуг свідчить відсутність станом на 01 число місяця,
                    наступного за місяцем в якому надавались послуги,
                    неврегульованих&nbsp;
                    <strong>Сторонами&nbsp;</strong>письмових скарг та зауважень
                    щодо якості та кількості наданих послуг.
                  </p>
                  <p>
                    8.7.&nbsp;<strong>Виконавець&nbsp;</strong>може надати&nbsp;
                    <strong>Споживачу</strong>, у тому числі на вимогу
                    останнього, оформлений належним чином&nbsp;
                    <strong>Акт приймання-передачі наданих послуг</strong>.
                    Вказаний&nbsp;<strong>Акт&nbsp;</strong>може бути наданий
                    після завершення певного етапу/частини&nbsp;
                    <strong>Послуг&nbsp;</strong>та/або остаточного завершення
                    виконання<strong>Послуг</strong>, передбачених у цьому
                    Договорі.
                  </p>
                  <p>
                    8.8.&nbsp;<strong>Споживач&nbsp;</strong>протягом 7
                    календарних днів від дати отримання
                    відповідного&nbsp;Актузобов'язується розглянути його і, в
                    разі відсутності зауважень, підписати і передати один
                    екземпляр<strong>Виконавцю</strong>. У разі якщо протягом 7
                    календарних днів від дати отримання&nbsp;
                    <strong>Актів Споживач&nbsp;</strong>їх не підпише або не
                    надасть зауваження до такого&nbsp;
                    <strong>Акту&nbsp;</strong>у письмовій формі, послуги
                    вважаються належно виконаними&nbsp;
                    <strong>Виконавцем</strong>, прийнятими&nbsp;
                    <strong>Споживачем&nbsp;</strong>в повному обсязі і без
                    зауважень. 8.9.&nbsp;<strong>Споживач&nbsp;</strong>не може
                    вимагати зниження вартості послуг чи повернення коштів, у
                    випадку,
                    <br />
                    якщо він не скористався послугами. Моментом виконання
                    надання послуг вважається передача доступу до Особистого
                    кабінету Користувача.
                  </p>
                  <p>
                    <br />
                    <h3>9. Гарантії</h3>
                    <br />
                    9.1.&nbsp;<strong>Послуги&nbsp;</strong>надаються&nbsp;
                    <strong>Виконавцем&nbsp;</strong>"Як Є" і в тому вигляді, в
                    якому вони доступні на момент надання, при цьому ніяких
                    гарантій прямих або непрямих, не надається (включаючи, але
                    не обмежуючись, гарантіями щодо використання&nbsp;
                    <strong>Послуг&nbsp;</strong>в конкретних цілях&nbsp;
                    <strong>Споживача</strong>).
                    <br />
                    9.2.&nbsp;<strong>Виконавець&nbsp;</strong>надає&nbsp;
                    <strong>Споживачу&nbsp;</strong>можливість отримання
                    консультацій по електронній пошті, телефону, або з
                    використанням інших методів комунікацій, відповідно до умов
                    його&nbsp;
                    <strong>Продукту</strong>.<br />
                    9.3. Обсяг консультацій обмежується конкретними питаннями,
                    пов'язаними з наданням&nbsp;<strong>Послуг&nbsp;</strong>і
                    не включає питання, що стосуються налаштування або
                    діагностики персонального комп'ютера і програмного
                    забезпечення&nbsp;
                    <strong>Споживача&nbsp;</strong>або навчання навичкам роботи
                    на комп'ютері або в мережі Інтернет.
                    <br />
                    9.4. За винятком гарантій, прямо вказаних у тексті
                    Договору,&nbsp;<strong>Виконавець&nbsp;</strong>не надає
                    ніяких інших
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
          <div title='Page 7'>
            <div>
              <div>
                <div>
                  <p>
                    прямих або непрямих гарантій за Договором і прямо
                    відмовляється від будь-яких гарантій або умов
                  </p>
                  <p>
                    щодо непорушення прав і відповідності&nbsp;
                    <strong>Послуг&nbsp;</strong>конкретним цілям&nbsp;
                    <strong>Споживача</strong>.
                  </p>
                  <br />
                  <h3>10. Право інтелектуальної власності</h3>
                  <br />

                  <p>
                    10.1. Всі аудіовізуальні твори та інші матеріали, що
                    використовуються для надання послуг
                    <strong>Виконавцем</strong>, є об&rsquo;єктами
                    інтелектуальної власності, та на них поширюється правова
                    охорона, передбачена чинним законодавством у сфері
                    інтелектуальної власності.
                    <br />
                    10.2. За цим договором&nbsp;
                    <strong>Виконавець&nbsp;</strong>
                    надає&nbsp;<strong>Споживачу&nbsp;</strong>права
                    використання таких об&rsquo;єктів права інтелектуальної
                    власності&nbsp;
                    <strong>Виконавця&nbsp;</strong>в рамках наданих&nbsp;
                    <strong>Споживачу Послуг</strong>.
                  </p>
                  <p>
                    10.3.&nbsp;<strong>Сторони&nbsp;</strong>погоджуються, що
                    правова охорона поширюється на форму вираження твору і не
                    поширюється на будь-які ідеї, теорії, принципи, методи,
                    процедури, процеси, системи, способи, концепції, відкриття,
                    навіть якщо вони виражені, описані, пояснені, проілюстровані
                    у творі.
                    <br />
                    10.4. Передача прав, зазначених у розділі 9 цього Договору
                    не тягне передачі або відчуження будь- яких інших прав&nbsp;
                    <strong>Виконавця&nbsp;</strong>прямо не вказаних в цьому
                    Договорі, не надає&nbsp;<strong>Споживачу&nbsp;</strong>
                    права на відтворення, розповсюдження, обнародування,
                    виготовлення будь-яких копій (включаючи технічні та резервні
                    копії).
                  </p>
                  <p>
                    10.5. Будь-яку плату за передачу прав, передбачених в цьому
                    Договорі, не передбачено.
                    <br />
                    10.6.&nbsp;<strong>Споживач&nbsp;</strong>може
                    використовувати об&rsquo;єкти інтелектуальної
                    власності&nbsp;
                    <strong>Виконавця&nbsp;</strong>тільки в межах тих прав і
                    тими способами, які передбачені цим Договором.
                    <br />
                    10.7.&nbsp;<strong>Споживач&nbsp;</strong>зобов&rsquo;язаний
                    не розголошувати та не передавати ідеї, теорії, принципи,
                    методи, процедури, процеси, системи, способи, концепції,
                    відкриття розроблені&nbsp;<strong>Виконавцем</strong>, у
                    відносинах з будь-якими третіми особами з метою отримання
                    прибутку від такого розголошення. У випадку порушення
                    гарантій, зазначених в даному пункті,&nbsp;
                    <strong>Споживач&nbsp;</strong>зобов&rsquo;язаний
                    відшкодувати<strong>Виконавцю&nbsp;</strong>всі спричинені
                    таким порушенням прямі і не прямі збитки (включаючи втрачену
                    вигоду) протягом 7 банківських днів з моменту отримання
                    вимоги від&nbsp;
                    <strong>Виконавця</strong>. Вимога вважається
                    отриманою&nbsp;
                    <strong>Споживачем</strong>, якщо вона надіслана&nbsp;
                    <strong>Споживачу&nbsp;</strong>засобами зв&rsquo;язку та за
                    адресою, що вказана&nbsp;<strong>Споживачем&nbsp;</strong>
                    при реєстрації на&nbsp;<strong>Сайті&nbsp;</strong>та/або у
                    цьому Договорі.
                  </p>
                  <p>
                    <br />
                    <h3>11. Відповідальність сторін</h3>
                    <br />
                    <br />
                    11.1 За порушення умов Договору&nbsp;
                    <strong>Сторони&nbsp;</strong>несуть відповідальність,
                    встановлену Договором і/або чинним законодавством України.
                    <br />
                    11.2&nbsp;<strong>Виконавець&nbsp;</strong>не несе
                    відповідальність перед&nbsp;
                    <strong>Споживачем&nbsp;</strong>або третьою стороною за
                    будь-які затримки і / або переривання доступу до&nbsp;
                    <strong>Сайту</strong>.<br />
                    11.3.&nbsp;<strong>Виконавець</strong>, ні за яких обставин
                    не несе відповідальності перед&nbsp;
                    <strong>Споживачем&nbsp;</strong>або третьою стороною за
                    Договором:
                    <br />
                    11.3.1. за будь-які дії / бездіяльність, що є прямим або
                    непрямим результатом дій / бездіяльності
                    <strong>Споживача&nbsp;</strong>та / або третіх осіб;
                    <br />
                    11.3.2. за будь-які заборгованості, непрямі збитки, втрачену
                    вигоду, втрати і витрати (включаючи всі судові витрати,
                    витрати на оплату послуг адвокатів та юристів, які виникли в
                    результаті судових процесів та рішень суду), що прямо чи
                    опосередковано стосуються послуг&nbsp;
                    <strong>Виконавця</strong>, незалежно від того, чи міг&nbsp;
                    <strong>Виконавець&nbsp;</strong>передбачити можливість
                    таких збитків чи ні.
                    <br />
                    11.3.3. за дії третіх осіб, які призвели до крадіжки тієї чи
                    іншої інформації&nbsp;<strong>Споживача</strong>;<br />
                    11.3.4. за проблеми, пов'язані з використанням&nbsp;
                    <strong>Споживачем&nbsp;</strong>неліцензійного програмного
                    та/або технічного забезпечення;
                    <br />
                    11.3.5. за некваліфіковані дії&nbsp;
                    <strong>Споживача</strong>, щодо користування&nbsp;
                    <strong>Послугами</strong>;<br />
                    11.3.6. за цілісність, достовірність та наявність сайтів і
                    даних&nbsp;<strong>Споживача&nbsp;</strong>на сервері&nbsp;
                    <strong>Виконавця</strong>. 11.4.&nbsp;
                    <strong>Споживач&nbsp;</strong>повністю відповідальний за
                    збереження отриманого від&nbsp;<strong>Виконавця</strong>, а
                    так само всіх додатково створених&nbsp;
                    <strong>Споживачем&nbsp;</strong>логінів / паролів
                    призначених для доступу до Особистого кабінету&nbsp;
                    <strong>Споживача&nbsp;</strong>і за збитки, які можуть
                    виникнути через їхнє несанкціоноване використання.
                    11.5.&nbsp;
                    <strong>Сторони&nbsp;</strong>не несуть відповідальність за
                    порушення своїх зобов'язань за цим Договором, якщо воно
                    сталося не з їх вини.&nbsp;<strong>Сторона&nbsp;</strong>
                    вважається невинною, якщо доведе, що вжила всіх залежних від
                    неї заходів для належного виконання зобов'язання.
                    <br />
                    11.6.&nbsp;<strong>Споживач&nbsp;</strong>використовує
                    отриману інформацію та консультації особисто з метою
                    отримання прибутку від застосування у спосіб, що не
                    заборонений цим Договором.&nbsp;
                    <strong>Виконавець&nbsp;</strong>не несе відповідальності за
                    подальше використання/невикористання&nbsp;
                    <strong>Споживачем&nbsp;</strong>отриманої інформації,
                    наслідки її застосування.
                    <br />
                    11.7. У випадку порушення п. 10.7 Договору, на вимогу&nbsp;
                    <strong>Виконавця Споживач&nbsp;</strong>крім відшкодування
                    завданої шкоди (що включає, але не обмежується втраченою
                    вигодою, прямими і непрямими збитками), додатково
                    зобов&rsquo;язується на вимогу&nbsp;
                    <strong>Виконавця&nbsp;</strong>сплатити штраф у 20-кратному
                    розмірі від вартості оплаченого&nbsp;
                    <strong>Продукту&nbsp;</strong>за кожен факт такого
                    порушення. Порушення можуть бути зафіксовані скрінами
                    екранів, програмними засобами чи фактами доступу до
                    Платформи третіх осіб
                  </p>
                  <p>чи будь-яким іншим розумним засобами.</p>
                  <p>
                    <br />
                    <h3>12. Термін дії та зміна умов Договору</h3>
                    <br />
                    12.3. Надання&nbsp;<strong>Послуг&nbsp;</strong>починається
                    з моменту надходження оплати на розрахунковий рахунок
                    <strong>Виконавця&nbsp;</strong>або з моменту, вказаного в
                    описі відповідного&nbsp;<strong>Продукту</strong>.<br />
                    12.4. Дія Договору поширюється на весь час від моменту
                    укладення і до моменту виконання
                    <strong>Сторонами&nbsp;</strong>всіх своїх зобов'язань по
                    ньому.
                    <br />
                    12.5. З моменту укладення Договору всі попередні усні та
                    письмові домовленості&nbsp;<strong>Сторін</strong>, що
                    стосуються предмету Договору, втрачають силу.
                    <br />
                    12.6. Договір залишається в силі в разі зміни
                    реквізитів&nbsp;
                    <strong>Сторін</strong>, зміни їх статутних документів,
                    включаючи, але не обмежуючись зміною власника,
                    організаційно-правової форми тощо.
                  </p>
                  <br />
                  <h3>13. Розірвання договору</h3>
                  <br />
                  <p>
                    13.3. Цей Договір може бути достроково розірвано:
                    <br />
                    13.3.1. За взаємною згодою&nbsp;Сторін.
                    <br />
                    13.3.2. В односторонньому порядку&nbsp;
                    <strong>Виконавцем&nbsp;</strong>при невиконанні
                    зобов&rsquo;язань або порушенні умов Договору&nbsp;
                    <strong>Споживачем</strong>, в тому числі, якщо&nbsp;
                    <strong>Споживач&nbsp;</strong>систематично (два і більше
                    разів) не дотримується умов Договору, не вчасно чи не в
                    повному обсязі виконує домашні завдання. При цьому&nbsp;
                    <strong>Виконавець&nbsp;</strong>має право заявити вимогу
                    про відшкодування завданої таким порушенням шкоди (збитків).
                    В такому випадку Договір вважається розірваним з моменту
                    отримання
                    <strong>Споживачем&nbsp;</strong>повідомлення про припинення
                    його дії, в цьому випадку невикористана частина авансу&nbsp;
                    <strong>Споживачу&nbsp;</strong>не повертається.
                    <br />
                    13.3.3. В односторонньому порядку&nbsp;
                    <strong>Споживачем</strong>, якщо&nbsp;
                    <strong>Виконавець&nbsp;</strong>своєчасно не приступив до
                    виконання зобов'язань за договором або скасував&nbsp;
                    <strong>Заходи</strong>, передбачені описом&nbsp;
                    <strong>Продукту</strong>. У випадку якщо значну частину
                    обсягу&nbsp;<strong>Послуги&nbsp;</strong>вже було виконано,
                    споживач має право розірвати договір лише стосовно частини
                    послуги або робіт, що залишилася.
                    <br />
                    13.3.4. За рішенням суду, внаслідок вимоги однієї із сторін
                    у разі істотного порушення цього Договору другою стороною.
                    Істотним в даному випадку є таке порушення Договору, коли
                    внаслідок завданої ним шкоди одна із сторін значною мірою
                    позбавляється того, на що вона розраховувала при укладенні
                    Договору.
                    <br />
                    13.3.5. В інших випадках, передбачених Договором та чинним
                    законодавством України.
                    <br />
                    13.4. Одностороннє розірвання Договору у випадках, не
                    передбачених цим Договором не допускається.
                    <br />
                    13.5. При розірванні Договору з ініціативи&nbsp;
                    <strong>Споживача</strong>, невикористана частина авансу
                    йому не повертається.
                    <br />
                    13.6. Зобов'язання&nbsp;<strong>Сторін&nbsp;</strong>за
                    Договором, які в силу своєї природи повинні продовжувати
                    діяти (включаючи зобов'язання по конфіденційності,
                    проведення взаєморозрахунків, використання інформації, але,
                    не обмежуючись зазначеним), залишаються в силі ще протягом 2
                    років після закінчення дії Договору.
                    <br />
                    1237. Розірвання даного Договору не звільняє&nbsp;
                    <strong>Сторони&nbsp;</strong>від відповідальності за
                    порушення умов Договору, що виникли протягом терміну його
                    дії.
                  </p>
                  <p>
                    <br />
                    <h3>14. Врегулювання суперечок</h3>
                    <br />
                    14.1.&nbsp;<strong>Сторони&nbsp;</strong>будуть регулювати
                    шляхом переговорів будь-які спірні питання, розбіжності або
                    претензії, які можуть виникнути щодо цього Договору або в
                    зв'язку з його виконанням.
                    <br />
                    14.2. Всі спірні питання розглядаються&nbsp;
                    <strong>Виконавцем&nbsp;</strong>при наявності письмової
                    заяви не пізніше 7
                  </p>
                  <p>
                    робочих днів з моменту виникнення спірної ситуації.
                    <br />
                    14.3. У разі якщо&nbsp;<strong>Сторони&nbsp;</strong>не
                    можуть досягти згоди щодо спірних питань шляхом переговорів,
                    то такі спори і розбіжності підлягають вирішенню в суді, за
                    місцем знаходження&nbsp;<strong>Виконавця&nbsp;</strong>
                    (Україна) згідно з чинним законодавством України.
                  </p>
                  <p>
                    <br />
                    <h3>15. Форс-мажор</h3>
                    <br />
                    15.1. Жодна із&nbsp;<strong>Сторін&nbsp;</strong>не несе
                    відповідальність за невиконання або неналежне виконання умов
                    даного Договору, у випадку дії обставин непереборної сили,
                    про які&nbsp;<strong>Сторони&nbsp;</strong>не могли знати
                    заздалегідь або не могли їх передбачити. До таких обставин
                    відносяться: пожежа, повінь, землетрус, цунамі, смерч,
                    ураган, тайфун, зсуви, снігові лавини, виверження вулканів
                    та інші природні катаклізми, війни, революції, державні
                    перевороти, страйки, диверсійні та терористичні акти,
                    пограбування, аварії в системі енергопостачання та зв'язку,
                    зміни законодавства, дії державних органів та їх посадових
                    осіб, якщо ці обставини безпосередньо впливають на виконання
                    даного Договору, і їх виникнення юридично засвідчені.
                    <br />
                    15.2.&nbsp;<strong>Сторона</strong>, якій стало відомо про
                    настання або наближення таких обставин, негайно повідомляє
                    про це іншу&nbsp;<strong>Сторону</strong>.<br />
                    15.3. Виконання цього Договору, повністю або частково,
                    припиняється на час дії таких обставин. Якщо дія обставин
                    непереборної сили триває більше 2 місяців, то Договір
                    вважається розірваним.
                  </p>
                  <br />
                  <h3>16. Інші умови</h3>
                  <br />
                  <p>
                    16.1. Договір, його укладання та виконання регулюється
                    чинним законодавством України. Всі питання, не врегульовані
                    Договором або врегульовані не повністю, регулюються
                    відповідно до матеріального права України.
                    <br />
                    16.2. Кожна&nbsp;<strong>Сторона&nbsp;</strong>гарантує
                    іншій&nbsp;<strong>Стороні</strong>, що володіє необхідною
                    дієздатністю, а рівно всіма правами і повноваженнями,
                    необхідними і достатніми для укладання та виконання даного
                    Договору відповідно до його умов.
                  </p>
                  <p>
                    16.3. У разі якщо одне чи більше положень Договору є з
                    будь-якої причини бути визнані недійсними, такими що не
                    мають юридичної сили, то така недійсність не впливає на
                    дійсність будь- якого іншого положення Договору, які
                    залишаються в силі.
                    <br />
                    16.4. Будь-які повідомлення за Договором можуть
                    спрямовуватися однією&nbsp;<strong>Стороною&nbsp;</strong>
                    іншій&nbsp;Стороніпоштою з повідомленням про вручення або
                    кур'єрською службою з підтвердженням доставки.
                    <br />
                    16.5. Не вступаючи в суперечність з умовами Договору,&nbsp;
                    <strong>Споживач&nbsp;</strong>і&nbsp;
                    <strong>Виконавець&nbsp;</strong>мають право в будь-який час
                    оформити Договір на надання&nbsp;
                    <strong>Послуг&nbsp;</strong>у формі письмового
                    двостороннього документа. 16.6. Питання створення і охорони
                    всіх прав і об'єктів інтелектуальної власності, які були
                    використані при виконанні умов цього Договору,
                    вирішується&nbsp;<strong>Виконавцем&nbsp;</strong>
                    самостійно.
                    <br />
                    16.7.&nbsp;<strong>Виконавець&nbsp;</strong>може
                    надати&nbsp;
                    <strong>Споживачу&nbsp;</strong>переклад цього Договору з
                    української на інші мови, проте в разі невідповідності між
                    умовами Договору українською мовою та її перекладом,
                    юридичну силу має виключно версія Договору українською
                    мовою.
                    <br />
                    16.8.&nbsp;<strong>Виконавець&nbsp;</strong>залишає за собою
                    право змінювати або доповнювати умови цієї оферти,
                    розмістивши на цій сторінці відповідне повідомлення і нову
                    редакцію оферти. Рекомендуємо часто перевіряти цю сторінку і
                    дату внесення останніх змін, зазначену в нижній частині
                    сторінки.
                    <br />
                    16.9.&nbsp;<strong>Споживач&nbsp;</strong>розуміє і
                    погоджуєтеся з тим, що користування&nbsp;
                    <strong>Послугами</strong>,&nbsp;
                    <strong>Контентом&nbsp;</strong>після зміни умов Оферти,
                    розглядається&nbsp;<strong>Виконавцем&nbsp;</strong>як
                    прийняття&nbsp;<strong>Споживачем&nbsp;</strong>цих умов в
                    новій редакції Оферти. У разі якщо&nbsp;
                    <strong>Споживач&nbsp;</strong>заперечує проти нової
                    редакції Оферти,&nbsp;Споживачповинен відправити&nbsp;
                    <strong>Виконавцю&nbsp;</strong>відповідне повідомлення
                    електронною поштою або поштою за адресою, вказаною на&nbsp;
                    <strong>Сайті</strong>. Однак&nbsp;
                    <strong>Виконавець&nbsp;</strong>зберігає за собою право на
                    односторонню відмову від договору в установленому порядку, в
                    разі якщо&nbsp;<strong>Споживач&nbsp;</strong>відмовився
                    прийняти умови цієї Оферти.
                  </p>
                  <br />
                  <h3>17. Місцезнаходження та реквізити сторін</h3>
                  <br />
                  <p>Виконавець</p>
                  <p>ФОП Степанян Софі Оганесівна</p>
                  <p>
                    Адреса: 69001, Україна, 69001, Запорізька обл., місто
                    Запоріжжя, вулиця 12 квітня, будинок 8
                    <br />
                    Код ЄДРПОУ: 3675601889
                    <br />
                    Номер запису (в ЄДР) 2010350000000469124 Дата: 19.12.2023 р.
                  </p>
                  <p>
                    Тел.: +38(066)-051-44-61
                    <br />
                    E-mail: sophiestepanyan0999@gmail.com
                  </p>
                  <p>
                    п/р UA633133990000026006000211045 Банк: Приват24
                    <br />
                    IBAN: UA633133990000026006000211045 МФО: 305299 ,
                  </p>
                  <p>
                    _____________
                    <br />
                    ФОП Степанян Софі Оганесівна
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </Card>
      </Row>
    </Layout>
  )
}

export default Oferta
